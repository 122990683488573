<template>
  <div class="fs-exclude" v-if="user && userCopy">
    <div class="mb-8 mt-1 text-xl font-semibold">Your Details</div>
    <transition-group name="fade" mode="out-in">
      <div
        v-if="detailSuccess"
        key="detail-success"
        class="mx-auto mb-6 flex h-16 items-center space-x-3 rounded-lg border-2 border-teal-400 bg-teal-100 px-4 py-4 text-left text-teal-600"
      >
        <font-awesome-icon
          :icon="['far', 'check']"
          class="text-xl text-teal-500"
        />
        <div>Your details have been updated successfully</div>
      </div>
      <div
        v-if="showDetailError"
        key="detail-error"
        class="mb-6 max-w-96 space-y-4 rounded border-2 border-red-400 bg-red-100 p-4 text-center text-red-500"
      >
        There was an error updating your details. Please try again or contact
        us.
      </div>
    </transition-group>

    <div class="input-group">
      <label>Name</label>
      <input type="text" v-model="userCopy.name" />
    </div>
    <div class="input-group">
      <label>Email</label>
      <input type="text" v-model="userCopy.email" />
    </div>
    <div class="input-group">
      <label>Phone</label>
      <input type="text" v-model="userCopy.phone" />
      <div class="mt-2 text-sm italic text-gray-500">
        Used for delivery updates & order related queries.
      </div>
    </div>
    <form @submit.prevent="updateDetails">
      <loading-button
        :is-loading="isDetailLoading"
        :is-enabled="detailsValid"
        label="Update"
      />
    </form>
    <hr class="mt-10" />
    <div class="mb-8 mt-6 text-xl font-semibold">Delivery Address</div>
    <transition name="fade" mode="out-in">
      <div
        v-if="addressSuccess"
        class="mx-auto mb-6 flex h-16 items-center space-x-3 rounded-lg border-2 border-teal-400 bg-teal-100 px-4 py-4 text-left text-teal-600"
      >
        <font-awesome-icon
          :icon="['far', 'check']"
          class="text-xl text-teal-500"
        />
        <div>Your address has been updated successfully</div>
      </div>
    </transition>
    <form @submit.prevent="updateAddress">
      <update-address
        :address="user.shippingAddress"
        @is-valid="checkAddress"
        show-error
        validate-postcode
      />
      <loading-button
        :is-loading="isLoading"
        :is-enabled="isEnabled"
        label="Update"
      />
    </form>
    <div
      v-if="
        subscription &&
        !subscription.isRemote &&
        !subscription.oldUser &&
        !subscription.deliveryTypeFreeUpgrade
      "
    >
      <hr class="mt-10" />
      <div class="mb-8 mt-6 text-xl font-semibold">Delivery Options</div>
      <div
        v-if="deliverySuccess"
        class="mx-auto mb-6 flex h-16 items-center space-x-3 rounded-lg border-2 border-teal-400 bg-teal-100 px-4 py-4 text-left text-teal-600"
      >
        <font-awesome-icon
          :icon="['far', 'check']"
          class="text-xl text-teal-500"
        />
        <div>Your delivery option has been updated successfully</div>
      </div>
      <div class="mb-6">
        <delivery-options
          v-if="subscription"
          :postcode="postcodeChecker"
          :active-option="subscription.deliveryTypeSelected"
          :subscription-price="subscriptionCost"
          @input="setActiveShipping"
        />
      </div>
      <form @submit.prevent="updateDelivery">
        <loading-button
          :is-loading="deliveryLoading"
          :is-enabled="true"
          label="Update"
        />
      </form>
    </div>
  </div>
</template>

<script>
import UpdateAddress from '@/components/account/UpdateAddress';
import LoadingButton from '@/components/LoadingButton';
import _ from 'lodash';
import http from '@/http';
import { mapGetters } from 'vuex';
import { checkPostcode } from '@/utilities/checkPostcode';
import DeliveryOptions from '@/components/delivery/DeliveryOptions';
export default {
  metaInfo: {
    title: 'Personal Details - Settings'
  },
  data() {
    return {
      isLoading: false,
      isDetailLoading: false,
      showDetailError: false,
      addressValid: false,
      addressSuccess: false,
      detailSuccess: false,
      userCopy: null,
      activeShipping: null,
      deliveryLoading: false,
      deliverySuccess: false
    };
  },
  computed: {
    ...mapGetters({
      user: 'getCurrentUser',
      subscription: 'getCurrentSubscription'
    }),

    isEnabled: function () {
      if (this.addressValid) {
        return true;
      } else {
        return false;
      }
    },
    detailsValid() {
      return this.userCopy.name && this.userCopy.email && this.userCopy.phone
        ? true
        : false;
    },
    subscriptionCost() {
      return this.subscription?.totalCost || null;
    },
    postcodeChecker() {
      if (this.userCopy.shippingAddress.postcode) {
        return checkPostcode(this.userCopy.shippingAddress.postcode, true);
      }
      return false;
    }
  },
  components: {
    UpdateAddress,
    LoadingButton,
    DeliveryOptions
  },
  methods: {
    updateAddress: function () {
      this.isLoading = true;
      this.addressSuccess = false;
      http
        .put('/me/address', {
          ...this.user.shippingAddress,
          postcode: this.user.shippingAddress.postcode.toUpperCase()
        })
        .then((response) => {
          this.isLoading = false;
          let user = this.$store.state.core.user;
          user.shippingAddress = response.data.content[0];
          this.addressSuccess = true;
          this.$store.dispatch('setUser', user);
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    checkAddress: function (value) {
      this.addressValid = value;
    },
    updateDetails: function () {
      this.isDetailLoading = true;
      this.detailSuccess = false;
      this.showDetailError = false;
      const data = {
        name: this.userCopy.name,
        phone: this.userCopy.phone,
        email: this.userCopy.email.trim()
      };
      if (this.user.email === this.userCopy.email) {
        delete data.email;
      }
      http
        .put('/me', data)
        .then(() => {
          this.isDetailLoading = false;
          this.$store.dispatch('getUser').then((response) => {
            this.userCopy = _.clone(response);
          });
          this.detailSuccess = true;
        })
        .catch((error) => {
          console.log(error);
          this.isDetailLoading = false;
          this.showDetailError = true;
        });
    },
    updateDelivery() {
      this.deliveryLoading = true;
      this.deliverySuccess = false;
      let data = {
        items: this.subscription.items,
        delivery: this.activeShipping.prices[0].id
      };

      http.put(this.subscription.links.adjust, data).then((response) => {
        this.deliveryLoading = false;

        this.$store.commit('update_subscription', response);
        this.deliverySuccess = true;
      });
    },
    setActiveShipping(response) {
      this.activeShipping = response;
    }
  },
  mounted() {
    this.$store.dispatch('getUser').then((response) => {
      this.userCopy = Object.assign({}, response, {});
    });
    if (!this.subscription) {
      this.$store.dispatch('getSubscription');
    }
  }
};
</script>

<style lang="css" scoped></style>
