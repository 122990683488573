import { includes, split, toUpper } from 'lodash/fp';
import mixpanel from 'mixpanel-browser';
const postcodes = [
  {
    label: 'Northern Ireland',
    canDeliver: true,
    postcodes: [
      'BT1',
      'BT2',
      'BT3',
      'BT4',
      'BT5',
      'BT6',
      'BT7',
      'BT8',
      'BT9',
      'BT10',
      'BT11',
      'BT12',
      'BT13',
      'BT14',
      'BT15',
      'BT16',
      'BT17',
      'BT18',
      'BT19',
      'BT20',
      'BT21',
      'BT22',
      'BT23',
      'BT24',
      'BT25',
      'BT26',
      'BT27',
      'BT28',
      'BT29',
      'BT30',
      'BT31',
      'BT32',
      'BT33',
      'BT34',
      'BT35',
      'BT36',
      'BT37',
      'BT38',
      'BT39',
      'BT40',
      'BT41',
      'BT42',
      'BT43',
      'BT44',
      'BT45',
      'BT46',
      'BT47',
      'BT48',
      'BT49',
      'BT51',
      'BT52',
      'BT53',
      'BT54',
      'BT55',
      'BT56',
      'BT57',
      'BT58',
      'BT60',
      'BT61',
      'BT62',
      'BT63',
      'BT64',
      'BT65',
      'BT66',
      'BT67',
      'BT68',
      'BT69',
      'BT70',
      'BT71',
      'BT74',
      'BT75',
      'BT76',
      'BT77',
      'BT78',
      'BT79',
      'BT80',
      'BT81',
      'BT82',
      'BT92',
      'BT93',
      'BT94'
    ]
  },
  {
    label: 'the Channel Islands',
    canDeliver: false,
    postcodes: [
      'GY1',
      'GY2',
      'GY3',
      'GY4',
      'GY5',
      'GY6',
      'GY7',
      'GY8',
      'GY9',
      'GY10',
      'GY11',
      'GY12',
      'GY13',
      'GY14',
      'GY15',
      'GY16'
    ]
  },
  {
    label: 'the Scottish Islands',
    canDeliver: false,
    postcodes: [
      'HS1',
      'HS2',
      'HS3',
      'HS4',
      'HS5',
      'HS6',
      'HS7',
      'HS8',
      'HS9',
      'KW15',
      'KW16',
      'KW17',
      'PA20',
      'PA38',
      'PA41',
      'PA42',
      'PA43',
      'PA44',
      'PA45',
      'PA46',
      'PA47',
      'PA48',
      'PA49',
      'PA60',
      'PA61',
      'PA62',
      'PA63',
      'PA64',
      'PA65',
      'PA66',
      'PA67',
      'PA68',
      'PA69',
      'PA70',
      'PA71',
      'PA72',
      'PA73',
      'PA74',
      'PA75',
      'PA76',
      'PA77',
      'PA78',
      'PH42',
      'PH43',
      'PH44',
      'ZE1',
      'ZE2',
      'ZE3'
    ]
  },

  {
    label: 'the Scottish Highlands',
    canDeliver: true,
    postcodes: [
      'FK17',
      'FK18',
      'FK19',
      'FK20',
      'FK21',
      'IV1',
      'IV2',
      'IV3',
      'IV4',
      'IV5',
      'IV6',
      'IV7',
      'IV8',
      'IV9',
      'IV10',
      'IV11',
      'IV12',
      'IV13',
      'IV14',
      'IV15',
      'IV16',
      'IV17',
      'IV18',
      'IV19',
      'IV20',
      'IV21',
      'IV22',
      'IV23',
      'IV24',
      'IV25',
      'IV26',
      'IV27',
      'IV28',
      'IV29',
      'IV30',
      'IV31',
      'IV32',
      'IV33',
      'IV34',
      'IV35',
      'IV36',
      'IV37',
      'IV38',
      'IV39',
      'IV52',
      'IV53',
      'IV54',
      'IV63',
      'KW1',
      'KW2',
      'KW3',
      'KW4',
      'KW5',
      'KW6',
      'KW7',
      'KW8',
      'KW9',
      'KW10',
      'KW11',
      'KW12',
      'KW13',
      'KW14',
      'PA21',
      'PA22',
      'PA23',
      'PA24',
      'PA25',
      'PA26',
      'PA27',
      'PA28',
      'PA29',
      'PA30',
      'PA31',
      'PA32',
      'PA33',
      'PA34',
      'PA35',
      'PA36',
      'PA37',
      'PA39',
      'PA40',
      'PH19',
      'PH20',
      'PH21',
      'PH22',
      'PH23',
      'PH24',
      'PH25',
      'PH26',
      'PH30',
      'PH31',
      'PH32',
      'PH33',
      'PH34',
      'PH35',
      'PH36',
      'PH37',
      'PH38',
      'PH39',
      'PH40',
      'PH41',
      'PH49',
      'PH50'
    ]
  },
  {
    label: 'Jersey',
    canDeliver: false,
    postcodes: ['JE3', 'JE1', 'JE2']
  },
  {
    label: 'Guernsey',
    canDeliver: false,
    postcodes: ['GY5', 'GY4', 'GY1', 'GY3']
  },
  {
    label: 'the Isle of Man',
    canDeliver: true,
    postcodes: ['IM1', 'IM2', 'IM3', 'IM4', 'IM5', 'IM6', 'IM7', 'IM8', 'IM9']
  },
  {
    label: 'the Isle of Wight',
    canDeliver: true,
    postcodes: [
      'PO30',
      'PO36',
      'PO31',
      'PO37',
      'PO32',
      'PO38',
      'PO33',
      'PO39',
      'PO34',
      'PO40',
      'PO35',
      'PO41'
    ]
  },
  {
    label: 'Orkney Isles',
    canDeliver: false,
    postcodes: ['KW15', 'KW16', 'KW17']
  },
  {
    label: 'Republic of Ireland',
    canDeliver: false,
    postcodes: [
      'D01',
      'D02',
      'D03',
      'D04',
      'D05',
      'D06',
      'D06W',
      'D07',
      'D08',
      'D09',
      'D10',
      'D11',
      'D12',
      'D13',
      'D14',
      'D15',
      'D16',
      'D17',
      'D18',
      'D20',
      'D22',
      'D24',
      'A41',
      'A42',
      'A45',
      'A63',
      'A67',
      'A75',
      'A81',
      'A82',
      'A83',
      'A84',
      'A85',
      'A86',
      'A91',
      'A92',
      'C15',
      'E21',
      'E25',
      'E32',
      'E34',
      'E41',
      'E45',
      'E53',
      'E91',
      'F12',
      'F23',
      'F26',
      'F28',
      'F31',
      'F35',
      'F42',
      'F45',
      'F52',
      'F56',
      'G32',
      'H12',
      'H14',
      'H16',
      'H18',
      'H23',
      'H53',
      'H54',
      'H62',
      'H65',
      'H71',
      'H91',
      'K32',
      'K34',
      'K36',
      'K45',
      'K56',
      'K67',
      'K78',
      'N37',
      'N39',
      'N41',
      'P12',
      'P14',
      'P17',
      'P24',
      'P25',
      'P31',
      'P32',
      'P36',
      'P43',
      'P47',
      'P51',
      'P56',
      'R14',
      'R21',
      'R32',
      'R35',
      'R42',
      'R45',
      'R51',
      'R56',
      'R93',
      'R95',
      'T12',
      'T23',
      'T34',
      'V14',
      'V15',
      'V23',
      'V31',
      'V35',
      'V42',
      'V92',
      'V93',
      'W23',
      'W34',
      'W91',
      'X35',
      'X42',
      'X91',
      'Y14',
      'Y21',
      'Y25',
      'Y34',
      'Y35'
    ]
  }
];

function checkPostcode(postcode, sendEvent) {
  postcode = postcode.replace(/^\s+/g, '');

  const prefix = split([' '], postcode)[0];
  let isFound;
  postcodes.forEach((p) => {
    const included = (val) => {
      return new RegExp(`^${val}`).test(toUpper(prefix));
    };

    if (p.postcodes.some(included)) {
      isFound = p;
      if (mixpanel && sendEvent) {
        mixpanel.track('Cant Deliver Postcode', { label: p.label });
      }
    }
  });

  return isFound ? isFound : false;
}

export { checkPostcode };
